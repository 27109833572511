.slide-info-app-parent-superuser {    
    width: 26em;
    height: 60em;
    padding-left: 1em;
    padding-right:1em;
}

.slide-info-app-parent {    
  width: 26em;
  height: 35em;
  padding-left: 1em;
  padding-right:1em;
}

.slide-info-app-parent-case-info {    
  width: 40em;
  height: 60em;
  padding-left: 1em;
  padding-right:1em;
}

.slide-info-text {
    font-size: 12px;
}

.slide-info-details {
    background-color: rgba(0, 0, 0, 0);
}

.ant-table-placeholder {
    background-color: rgba(0, 0, 0, 0);
}

.slide-info-table {
  max-height: 20em;
  width: 26em;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0);
}

.comment-info-table {
  max-height: 23em;
  width: 42em;
  /* overflow-y: auto; */
  background-color: rgba(0, 0, 0, 0);
}

.scroll {
  max-height: 800px;
  overflow-y: auto;
}

.slide-description-scroll {
  max-height: 320px;
  overflow-y: auto;
  white-space:pre-wrap;
}

.slide-description-scroll-superuser {
  max-height: 200px;
  overflow-y: auto;
  white-space:pre-wrap;
}

.scroll-slide-info {
  max-height: 560px;
  overflow-y: auto;
}