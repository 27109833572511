.present-app-parent {
    width: 10em;
    padding: 0.5em;
    padding-top: 2em;
}

.present-button {
    margin: auto;
    display: block;
    height: 8em;
    width: 8em;
    background-color: rgba(0, 0, 0, 0) !important;
    color: var(--theme-text-color) !important;
    border: 0px;
}

.present-button:hover {
    color: var(--theme-text-color) !important;
    background-color: rgba(170, 170, 170, 0.2) !important;
}

.present-button:focus {
    color: var(--theme-text-color) !important;
}

.present-icon {
    font-size: 70px;
    background-color: rgba(0, 0, 0, 0) !important;
    color: var(--theme-text-color);
}

.present-icon-presenting {
    color: #71dd62;
}