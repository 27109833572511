.motion-app-width {
    width: 650px;
}

.motion-app-live-mode-width {
    width: 160px;
}

.motion-app-heading {
    text-align: center;
    margin: 5px;
}

.motion-app-input-field {
    width: 85px;
}