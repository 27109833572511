.comments-body {
    overflow-y: auto;
    max-height: 140px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
    color: var(--theme-text-color);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px var(--theme-text-color) solid;
    border-bottom: none;
}

.ant-comment-content{
    padding: 2%;
    border-radius: 5px;
    margin-bottom: 2%;
}

.ant-comment-content-author > a, .ant-comment-content-author > span{
    height: 13px !important;
    font-size: 11px !important;
    line-height: 16px !important;
    padding-right: 12px !important;
    color: var(--theme-text-color);
}