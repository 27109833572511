.preview-component {
    width: 20em;
}

.preview-map {
    position: absolute !important;
    position: unset !important;
    border-radius: 4px !important;
    padding: 0.2em !important;
    right: 0em;
    top: 0em;
}

#preview-map-to-target button {
    display: none!important;
}

.preview-tracking-component {
    margin-left:1em;
    margin-right:1em;
}

.preview-tracking-legend {
    font-size: 10px;
    margin: auto;
}

#preview-map-to-target .ol-viewport {
    border-radius: 5px !important;
    height: 98vh
}

#preview-map-to-target .ol-control {
    background-color: rgba(0, 0, 0, 0);
}

.preview-tracking-text, .preview-tracking-switch {
    float: right;
    font-size: 10px;
    margin: 0.2em;
}