.brush-size-slider {
    display: inline-block;
    width: 150px;
}

.vertical-radio-buttons {
    display: block;
    height: 30px;
    line-height: 30px;
}

.top-child-vertical-radio-buttons {
    border-radius: 4px 4px 0 0 !important;
}

.bottom-child-vertical-radio-buttons {
    border-radius: 0 0 4px 4px !important;
}

.scan-settings-box-left {
    padding: 20px;
    border: 1px solid black;
    border-radius: 10px 0 0 10px;
}

.scan-settings-box-right {
    padding: 20px;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    border-radius: 0 10px 10px 0;
}

.scan-settings-parent {
	display: -webkit-flex; /* Safari */		
	display: flex; /* Standard syntax */
}

.z-stack-scan-input-field {
    width: 80%;
}

.z-stack-scan-text {
    margin-top: 5px;
}

.relative-parent {
    position: relative;
    height: 80vh;
}

.save-changes-row {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.properties-width {
    height: 86vh;
}

.tab-section-height {
    height: 82vh;
    background-color: rgb(227, 233, 231);
    padding-left: 10px !important;
    border: 1px solid rgb(227, 233, 231);
    border-radius: 5px;
}

.tab-section-height .ant-tabs-bar {
    border-bottom: 1px solid white;
}

.save-button-height {
    height: 4vh;
    margin-top: 10px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.divider-style {
    background-color: #e8e8e8;
}

.side-panel-section {
    margin-bottom: 15px;
}

.react-region-select-custom--style > div > div > div[data-dir=se] {
    width: 20px !important;
    height: 20px !important;
    bottom: -8px !important;
    right: -8px !important;
}

.react-region-select-custom--style > div > div > div[data-dir=sw] {
    width: 20px !important;
    height: 20px !important;
    bottom: -8px !important;
    left: -8px !important;
}

.react-region-select-custom--style > div > div > div[data-dir=nw] {
    width: 20px !important;
    height: 20px !important;
    top: -8px !important;
    left: -8px !important;
}

.react-region-select-custom--style > div > div > div[data-dir=ne] {
    width: 20px !important;
    height: 20px !important;
    top: -8px !important;
    right: -8px !important;
}

.selected-area-component {
    font-size: 15px; 
    font-weight: bold; 
    text-align: center;
}