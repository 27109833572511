.zoom-controls-button {
    margin-bottom: 0.2em;
    height: 50px;
    width: 50px;
    text-align: center;
    border:none;
    background-color: rgba(0, 0, 0, 0);
    color: var(--theme-text-color);
}

.zoom-controls-button:hover, .zoom-controls-button:focus {
    background-color: rgba(170, 170, 170, 0.2);
    color: var(--theme-text-color);
}

.plus-button, .digizoom-button {
    margin-bottom: 0em;
    border-bottom-left-radius: 0em !important;
    border-bottom-right-radius: 0em !important;
    transition: none
}

.digizoom-button {
    font-size: 10px;
    padding: 0em;
    background-color: rgb(255, 208, 0) !important;
}

.digizoom-button:hover {
    background-color: rgb(26, 32, 54) !important;
    color: white;
}

.digizoom-button-disabled {
    font-size: 10px;
    padding: 0em;
    background-color: rgb(255, 208, 0) !important;
}

.digizoom-button-disabled:hover {
    background-color: rgb(255, 208, 0) !important;
    color: white;
}

.minus-button {
    margin-top: 0em;
    border-top-left-radius: 0em !important;
    border-top-right-radius: 0em !important;
}

.zoom-level-button {
    margin-top: 0em;
    margin-bottom: 0em;
    border-radius: 0em !important;
    height:3em;
    line-height: 3em !important;
    padding: 0em;
    font-weight: bold;
    cursor: default !important;
    border: 1px solid transparent;
    border-color:#d9d9d9;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    display: inline-block;
    vertical-align: middle;
    border:none;
    line-height: normal;
}

.zoom-level-button:hover {
    background-color: rgba(0, 0, 0, 0);
    color: var(--theme-text-color);
}

.zoom-controls-icon {
    font-size: 1.3em;
}