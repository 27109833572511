.manual-mode-container {
    width: 100% !important;
    height: 99.8vh !important;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 0;
}

.border {
    border: 1px solid black;
    border-radius: 10px 0px 0px 10px;
}

.border-button {
    border: 1px solid black;
    border-radius: 4px 0px 0px 4px;
}

.app-icon-manual-mode {
    font-size: 15px;
    border-radius: 0.2em;
    padding: 0.7em;
    margin:0.3em;
    cursor: pointer;
    height: 3em;
    width: 16em;
    color: var(--theme-text-color) !important;
}

.app-icon-manual-mode:hover {
    -webkit-box-shadow: inset 0px 0px 6px 0px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: inset 0px 0px 6px 0px rgba(0, 0, 0, 0.46);
    box-shadow: inset 0px 0px 6px 0px rgba(0, 0, 0, 0.46);
}

.app-icon-open-manual-mode, .app-icon-open-manual-mode:hover {
    -webkit-box-shadow: inset 0px 0px 3px 0.5px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: inset 0px 0px 3px 0.5px rgba(0, 0, 0, 0.46);
    box-shadow: inset 0px 0px 3px 0.5px rgba(0, 0, 0, 0.46);
}

.app-icon-closeable-handle-manualmode {
    font-size: 15px;
    position: absolute;
    right: 1.5em;
}

.app-icon-title-manualmode {
    padding-left: 20px;
}

.buttons-row-margin-side-pan {
    margin-top: 5px;
    margin-left: 4px;
}

.settings-box {
    background: rgb(255, 255, 255);
    position: absolute;
    left: 3vh;
    bottom: 0;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.3) !important;
    z-index: 2147483640;
    border-radius: 5px 5px 0 0;
    padding: 16px 24px;
    line-height: 1.5715;
    width: 300px;
    max-width: calc(100vw - 48px);
    margin-bottom: 16px;
    margin-left: auto;
    overflow: hidden;
    border-radius: 2px;
}
