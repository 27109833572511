.input-field {
    width: 250px;
}

.row-heading {
    margin-top: 7px;
}

.error-message-general {
    color: #ba2121;
  }