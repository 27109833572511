.advanced-settings-preview-map-container {
    width: 100%;
    height: 70vh;
    z-index: 0;
}

.forty-x-image {
    width: 600px;
}

.blank-forty-x-image {
    width: 600px;
    height: 502px;
    border: 1px solid black;
    border-radius: 5px;
    background-image: url("../../img/empty_image.jpg");
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: bold;
    padding: 30px;
    margin-top: 10px;
}

.actionable-div {
    width: 600px;
    text-align: center;
}

.actionable-div-left-align {
    width: 600px;
}