.appboard {
    filter: none !important;
    height: 100% !important;
    width: 100% !important;
    position: absolute;
    pointer-events: none;
}

.appboard-parent {
    position: absolute;
    top: 2em;
    left: 2em;
    height: 4em !important;
    width: 4em !important;
    pointer-events: all;
}

.appboard-parent-present-mode {
    position: absolute;
    top: 2em;
    left: 2em;
    height: 4em !important;
    width: 4em !important;
    pointer-events: all;
    display: none;
}

.appboard-trigger {
    height: 100%;
    width: 100%;
    padding: 0.5em;
    margin: auto;
    display: block;
    cursor: pointer;
}

.appboard-closed {
    height: 4em !important;
    width: 4em !important;
    z-index: 200;
}

.appboard-expanded {
    height: auto !important;
    width: auto !important;
    z-index: 200 !important;
}

.appboard {
    z-index: 1;
}

.morphle-watermark {
    bottom: 1em;
    height: 3em;
    left: 1em;
    position: absolute;
    z-index: 2;
    opacity: 0.6;
    visibility: hidden;
}

.appboard > * {
    color: var(--theme-text-color);
}

.ant-typography {
    color: var(--theme-text-color);
}
