.qc-settings-app-width {
    width: 450px;
}

.qc-settings-app-heading {
    text-align: center;
    margin: 5px;
}

.qc-settings-app-input-field {
    width: 110px;
}