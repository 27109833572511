.custom-hover-table .ant-table .ant-table-tbody > tr:hover > td {
    background: unset;
}

.normal-cursor {
  cursor: default;
}

.custom-hover-table-box:hover {
  /* box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 10px 10px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
  -moz-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 10px 10px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
  -webkit-box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 10px 10px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
  z-index: 1; */
  background: rgba(198, 223, 240, 0.902);
}

.custom-hover-table-case .ant-table .ant-table-tbody > tr:hover > td {
  background: unset;
}

.custom-hover-table-case .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.custom-hover-table-case .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.custom-hover-table-case .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.custom-hover-table-case .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: unset;
}

.unread {
  background: rgba(255,255,255,0.902);
}

.read {
  background: rgba(242,245,245,0.8);
}

.custom-table-selection .ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #c2dbff;
}

.custom-table-selection .ant-table-tbody > tr:hover.ant-table-row-selected td {
  background: #c2dbff;
}

.custom-table-header .ant-table-thead > tr > th .ant-table-header-column {
  display: block;
  max-width: 100%;
}

/* .custom-table-header .ant-table-thead > tr {
  background-color: #3b7199;
}

.custom-table-header .ant-table-thead > tr:hover {
  background-color: #3b7199;
} */

.custom-table-height .ant-checkbox {
  padding: 5px !important;
}