.dashboard-menu {
    background-color: var(--main-bg-color);
}

.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

.login-user-prefix {
  font-size: 12px;
}

.login-user {
  font-size: 14px;
  font-weight: bold;
}

.login-user-row {
  text-align: center;
}

.back-top {
  height: 80px;
  width: 80px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #96d9f8;
  text-align: center;
  font-size: 40px;
}

.logo {
  height: 3em;
  margin: 1.5em;
  cursor: pointer;
}

.mobile-header {
  padding-top: 1em;
  background: var(--main-bg-color);
}

.logo-mobile {
  height: 5em;
  margin: 1.5em;
  cursor: pointer;
  margin: auto;
  display:block;
}

.logo-square {
  height: 3em;
  margin: 1.5em;
  cursor: pointer;
}

.sider {
  background: var(--main-bg-color) !important;
  border-right: 1px solid #e8e8e8;
}

.empty-white {
  height:100%;
  background: var(--main-bg-color) !important;
}

.side-menu {
  background: var(--main-bg-color);
  margin-top: 3em !important;
}

.side-menu-border {
  border: none !important;
}

.bm-burger-button {
  position:fixed;
}

.unselectable {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.scrollbar {
	float: left;
	height: 300px;
	overflow-y: scroll;
}

.scrollbar::-webkit-scrollbar {
	width: 5px;
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(179, 179, 179, 0.3);
	background-color: #F5F5F5;
}

.scrollbar::-webkit-scrollbar-thumb {
	background-color: #aaaaaa;
	border: 2px solid #aaaaaa;
}

.table-row-selected {
  background-color: #ADD8E6;
}

.ant-menu-item-group-title {
  padding: 0px 0px !important;
}
