.box-component {
    width: 20em;
}

.slider-height {
    height: 200px;
    margin: 0px !important;
}

.input-number-width {
    width: 70px !important;
}

.custom-slider-style .ant-slider-vertical {
    margin: 0px !important;
    padding: 0 0px !important;
}

.adjustment-error {
    position: absolute;
    left:110px;
    top: 40px;
    z-index: 100;
    opacity: 1;
}