.all-slides {
    padding-top:10px !important;
    padding-bottom:10px !important;
    padding-left:30px !important;
    padding-right:30px !important;
    background-color: white;
}

.pagination-mp{
    float: right;
    margin-top : 5px !important;
    margin-bottom : 5px !important;
}

.row-actions {
    margin: 5px;
}

.slide-table-rows {
    cursor: pointer;
}

.slidelist-tag {
    font-size: "0.9em";
    cursor : pointer !important;
    margin: '3px 3px'; 
}

.search-result-tag {
    margin-right:10px;
    margin-bottom: 5px;
}

.search-input {
    width: 100%;
    margin-bottom:20px !important;
}

.search-tag {
    width: 200px;
    margin-bottom:20px !important;
}

.fixed-controls-dashboard {
    z-index:1;
    background-color: white;

}

.ant-checkbox {
    padding:25px !important;
}

.tags-section {
    border-top: 1px solid #1890ff4d;
    border-bottom: 1px solid #1890ff4d;
    padding: 10px;
}

.tags-button {
    margin: 5px;
}

.lighter-primary-button {
    border-color: #1890ff99 !important;
}

.lighter-danger-button {
    border-color: #ff4d4fcc !important;
}

.text-style {
    font-size: 12px;
}

.custom-table-padding > td {
    padding: 5px 5px !important;
}

.slide-icons {
    font-size: 18px; 
    padding: 5px; 
    display: 'inline-table';
}

.underline-hover:hover {
    text-decoration: underline;
}

.custom-font-family .ant-progress-inner .ant-progress-text {
    font-family: 'ubuntu';
}