.camera-app-live-mode-width {
    width: 1307px;
}

.camera-app-width {
    width: 1090px;
}

.camera-app-width-fullscreen {
    width: 1510px;
}

.camera-app-width-quadrant {
    width: 965px;
}

.vertical-radio-buttons {
    display: block;
    height: 30px;
    line-height: 30px;
}

.top-most-radio-button {
    border-radius: 4px 4px 0 0 !important;
}

.bottom-most-radio-button {
    border-radius: 0 0 4px 4px !important;
}

.quadrant-image {
    max-width: 900px;
    max-height: 900px;
    overflow: auto;
    scrollbar-width: thin;
}

.camera-app-input-field {
    width: 72px;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    padding: 3px;
}

.display-inline {
    display: inline-block;
}

.display-block {
    display: block;
}
  
.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}
  
.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
  
.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}
  
.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.line-horizontal {
    height: 1px;
    border-top: 1px solid rgba(0, 0, 0, 0.85);
    margin: 4px 0;
}

.width-normal {
    width: 42.5%;
}

.width-normal-manual-mode {
    width: 40.5%;
}

.width-fullscreen {
    width: 43%;
}

.width-fullscreen-manual-mode {
    width: 43.5%;
}

.line-vertical {
    width: 1px;
    border-left: 1px solid rgba(0, 0, 0, 0.85);
    margin: 0 4px;
}

.line-inner-text-horizontal {
    padding: 0 28px;
    font-weight: 500;
}

.line-inner-text-vertical {
    padding: 5px 0;
    font-weight: 500;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    margin-left: -5px;
}