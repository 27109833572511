.carousel {
    margin-top: 30px;
}

.carousel-item {
    border: 2px solid black;
    height: 420px;
    width: 150px;
    background-position-y: center; 
    background-size: 100%;
    background-color: rgb(255 255 255 / 50%);
    background-blend-mode: screen;
}

.scanning {
    border: 2px solid rgb(104, 87, 87);
    height: 480px;
    width: 160px;
}

@keyframes shadow-pulse4 {
    0% {
        box-shadow: 0 0 0 0 rgb(44, 159, 204, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgb(44, 119, 204, 0.8);
    }
    100% {
        box-shadow: 0 0 0 0 rgb(44, 204, 191, 0.2);
    }
}