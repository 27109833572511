.login-logo-mp{
    background-image: url(../img/logo-mp.png);
}

.whoop-mp{
    background-image: url(../img/whoop-02.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat
}


@media (min-width: 1400){
    .ant-card-cover{
        min-height : 500px !important ;
    }
}

#map2 .ol-zoom .ol-zoom-out {
    margin-top: 204px;
}

.ol-scale-line {
    left: 89vw;
    background: rgba(0,60,136,0.9) !important
}

#map2 .ol-zoomslider {
  background-color: transparent;
  top: 2.3em;
}

#map2 .ol-touch .ol-zoom .ol-zoom-out {
  margin-top: 212px;
}
#map2 .ol-touch .ol-zoomslider {
  top: 2.75em;
}

.ol-zoom .ol-zoom-in {
  width: 40% !important;
}

.ol-zoom .ol-zoom-out {
  width: 40% !important;
}

#map2 .ol-zoom-in.ol-has-tooltip:hover [role=tooltip],
#map2 .ol-zoom-in.ol-has-tooltip:focus [role=tooltip] {
  top: 3px;
}

#map2 .ol-zoom-out.ol-has-tooltip:hover [role=tooltip],
#map2 .ol-zoom-out.ol-has-tooltip:focus [role=tooltip] {
  top: 232px;
}

.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  padding: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}

.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: relative;
  left: 95%;
}
.ol-popup-closer:after {
  content: "✖";
}

.ant-list-header, .ant-list-footer{
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-size: 0.8rem !important;
  font-weight: bolder !important;
}

.morphle-title {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-size: 1rem !important;
  text-align: center;
  margin-bottom: 3.5em;
}

#fs-mp-0 {
  text-align: center
}

.upload-list-inline .ant-upload-list-item {
  float: left;
  width: "50%";
  margin-right: 8px;
  background: #ffffffff !important;
}

.upload-list-inline .ant-upload-list-item:hover {
  background: #d6d7e4 !important;
}

.caseViewSlideItem {
  float: left;
  width: "100%";
  margin-right: 8px;
  background: #ffffffff !important;
  position: relative;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.caseViewSlideItem:hover {
  background: #d6d7e4 !important;
}

.ant-upload {
  cursor: pointer !important
}

.icon-hover:hover {
  display: inline-flex;
  border-radius: 10%;
  background-color: #FFFFFF;
  transform:scale(1.1);
  -moz-transform:scale(1.1);
  -webkit-transform:scale(1.1);
  -ms-transform:scale(1.1);
  -o-transform:scale(1.1);
}

.rotate270 {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.scroll-slide-list-modal {
  max-height: 150px;
  overflow-y: scroll;
}

.custom-button-style, .custom-button-style:hover {
  background-repeat: no-repeat;
  background-position: right 7px center;
  display: block;
  padding: 3px 12px;
  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #fff;
  border-radius: 5px;
}

.custom-button-background {
  background-color: #999;
}

.custom-button-background:hover {
  background-color: #417690;
}

.align-right {
  align-content: right;
}

.disable-button-present-mode .ant-btn-disabled, .ant-btn.disabled, .ant-btn[disabled], .ant-btn-disabled:hover, .ant-btn.disabled:hover, .ant-btn[disabled]:hover, .ant-btn-disabled:focus, .ant-btn.disabled:focus, .ant-btn[disabled]:focus, .ant-btn-disabled:active, .ant-btn.disabled:active, .ant-btn[disabled]:active, .ant-btn-disabled.active, .ant-btn.disabled.active, .ant-btn[disabled].active {
  color: rgba(0, 0, 0, 0.8) !important;
  background-color: #ffffff;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.selected-scan-message {
    -webkit-font-smoothing: antialiased;
    font-family: Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
    font-size: .875rem;
    letter-spacing: .2px;
    -webkit-align-items: center;
    align-items: center;
    background-color: rgba(241,243,244,0.871);
    border: none;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #5f6368;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    margin: 4px 0;
    min-height: 48px;
    padding: 6px 16px;
}

.select-all-scans-message, .select-all-scans-message:hover {
    align-items: center;
    border: none;
    display: inline-flex;
    justify-content: center;
    outline: none;
    position: relative;
    z-index: 0;
    -webkit-font-smoothing: antialiased;
    font-family: 'Google Sans', Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
    font-size: .875rem;
    letter-spacing: .25px;
    background: none;
    border-radius: 4px;
    box-sizing: border-box;
    color: #5f6368;
    cursor: pointer;
    font-weight: 500;
    height: 36px;
    min-width: 80px;
    outline: none;
    color: #1a73e8;
    margin-left: 4px;
    padding: 0 8px;
    text-decoration: none;
    white-space: pre-wrap;
}

.select-all-scans-message:hover {
    text-decoration: underline;
}