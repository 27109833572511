.saved-images-app-width {
    width: 840px;
}

.thumbs-style .carousel .thumbs-wrapper {
    overflow-x: scroll;
}

.thumb {
    height: 100px;
}