.preview-tuning-app-width {
    width: 1070px;
}

.relative-parent {
    position: relative;
}

.absolute-child {
    position: absolute;
}