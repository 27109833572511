.preview-app-width {
    width: 870px;
}

.preview-app-live-mode-width {
    width: 325px;
}

.white-space {
    white-space: normal;
    height: auto;
}

.map {
    width: 95%;
    height: 700px;
}