@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-LightItalic.woff2') format('woff2'),
        url('Ubuntu-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Web Pro Condensed';
    src: url('MyriadWebPro-Condensed.woff2') format('woff2'),
        url('MyriadWebPro-Condensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Web Pro';
    src: url('MyriadWebPro-Bold.woff2') format('woff2'),
        url('MyriadWebPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-Regular.woff2') format('woff2'),
        url('Ubuntu-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-Medium.woff2') format('woff2'),
        url('Ubuntu-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-MediumItalic.woff2') format('woff2'),
        url('Ubuntu-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-Bold.woff2') format('woff2'),
        url('Ubuntu-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Web Pro';
    src: url('MyriadWebPro.woff2') format('woff2'),
        url('MyriadWebPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-Italic.woff2') format('woff2'),
        url('Ubuntu-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-Light.woff2') format('woff2'),
        url('Ubuntu-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Ubuntu';
    src: url('Ubuntu-BoldItalic.woff2') format('woff2'),
        url('Ubuntu-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-SemiCn.woff2') format('woff2'),
        url('MyriadPro-SemiCn.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-Light.woff2') format('woff2'),
        url('MyriadPro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-LightIt.woff2') format('woff2'),
        url('MyriadPro-LightIt.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-SemiboldCond.woff2') format('woff2'),
        url('MyriadPro-SemiboldCond.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-Black.woff2') format('woff2'),
        url('MyriadPro-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-BlackIt.woff2') format('woff2'),
        url('MyriadPro-BlackIt.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-SemiExt.woff2') format('woff2'),
        url('MyriadPro-SemiExt.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-SemiboldSemiCn.woff2') format('woff2'),
        url('MyriadPro-SemiboldSemiCn.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Myriad Pro';
    src: url('MyriadPro-LightSemiCn.woff2') format('woff2'),
        url('MyriadPro-LightSemiCn.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

