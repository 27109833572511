.main-camera-preview-app-width {
    width: 403px;
}

.errormessage {
    margin-right: 10px;
    padding: 0px;
    color: #ba2121;
    background: #fff;
    text-align: center;
  }