.cap-guide-app-parent {    
    width: 46em;
    height: 45em;
    padding-left: 1em;
    padding-right:1em;
}

.cap-pdf-div {
    overflow-y: scroll;
    height: 35em;
}

.cap-guide-select {
    width: 100%;
    margin-top: 2em;
    margin-bottom: 0.5em;
}