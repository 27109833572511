.ihc-app-parent {
    width: 60em;
    padding: 0.5em;
    padding-top: 2em;
    min-height:100vh;
}

.screenshot-filename-text {
    font-size: 12px;
}

.send-for-ihc-button {
    text-align: center;
    padding-top: 0.5em;
}

.ihc-list, .ihc-drawer-row {
    max-height: 77em;
}