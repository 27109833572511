.expandable-enter, .expandable-exit {
    transition: all 0.2s linear;
}

.close-expandable-icon {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    cursor: pointer;
    z-index: 100;
    font-size: 15px
}