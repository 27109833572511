.report-app-parent {    
    width: 46em;
    height: 10em;
    padding-left: 1em;
    padding-right:1em;
}

.report-select {
    width: 100%;
    margin-top: 2em;
    margin-bottom: 0.5em;
}

.no-case-icon {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1em;
    font-size: 100px;
    display: block;
}

.no-case-div {
    text-align: center;
    margin-top: 4em;
    line-height: 2em;
}

.tox-notifications-container {
    display: none;
}

.revert-text {
    font-size: 12px;   
    float: right;
    vertical-align: middle;
    line-height: 3em;
}

.revert {
    margin-top: 2em;
}

.save {
    width:50%;
    margin: auto;
    display: block;
    margin-top: 1em;
}