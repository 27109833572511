.screenshot-app-parent {
    width: 28em;
    padding: 0.5em;
    padding-top: 2em;
}

.screenshot-filename-text {
    font-size: 12px;
}

.screenshot-save-button {
    text-align: center;
    padding-top: 0.5em;
}

.screenshot-name-input .ant-input{
    font-size: 12px;
    background-color: var(--main-bg-color);
    color: var(--theme-text-color);
}

.screenshot-name-input .ant-input:hover {
    border: 2px;
    border-color: var(--theme-text-color) !important;
}