.closeable-handle {
    cursor: pointer;
    z-index: 10;
    touch-action: manipulation;
    margin-bottom: 0.4em;
    display: inline-block;  
    position: absolute;
    top: -0.2em;
    right: 0.1em;
    font-size: 14px;
    margin: 0.2em;
}

.close-icon {
    line-height:2em !important;
    margin:auto;
    display:block;
}

.app-icon, .dashboard-icon {
    font-size: 30px;
    border-radius: 0.2em;
    padding: 0.2em;
    margin:0.3em;
    text-align:center;
    cursor: pointer;
    height: 3em;
    width: 3em;
    color: var(--theme-text-color) !important;
}

.app-icon:hover {
    -webkit-box-shadow: inset 0px 0px 6px 0px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: inset 0px 0px 6px 0px rgba(0, 0, 0, 0.46);
    box-shadow: inset 0px 0px 6px 0px rgba(0, 0, 0, 0.46);
}

.app-icon-text {
    font-size: 10px;
    color: var(--theme-text-color) !important;
}

.app-icon-open, .app-icon-open:hover {
    -webkit-box-shadow: inset 0px 0px 3px 0.5px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: inset 0px 0px 3px 0.5px rgba(0, 0, 0, 0.46);
    box-shadow: inset 0px 0px 3px 0.5px rgba(0, 0, 0, 0.46);
}

.app-parent {
    padding-top:1.5em;
}

.app-icon-closeable-handle {
    font-size: 15px;
    position: absolute;
    right: 0.2em;
}

.app-title {
    position: absolute;
    z-index: 12;
    font-size: 12px;
    height: 0px;
    overflow: visible;
    pointer-events: none;
    text-align: center;
    width: 100%;
    top: 0.25em;
}