.start-scan-button-div, .loader-action-div, .view-summary-button-div {
    text-align: center;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 2em;
    padding-top: 0.5em;
}

.loader-action-div {
    padding-top: 2em;
}

.start-scan-button, .loader-action-button-active {
    animation: shadow-pulse 1s infinite;
}

.tray-panel {

}

.selected-scan-info-panel {

}

.loader-action-panel {
    margin-right: 0.5em !important;
}

.loader-action-panel .ant-btn-group {
    margin: auto;
    display: table;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.tray-div {
    margin: 0.5em !important;
    border: 1px solid rgba(206, 206, 206, 0.712);
    border-radius: 0.5em;
    min-height: 5em !important;
    vertical-align: middle;
}

.tray-panel-inside-div {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.tray-div-no-cassette {
    color: white;
    background-color: #0a000054;
    text-align: center;
    font-size: 12px;
}

.tray-div-loaded-for-scanning {
    color: black;
    border: 2px solid orange;
    text-align: center;
    font-size: 12px;
    animation: shadow-pulse3 1.5s infinite;
}

.tray-div-scan-done {
    color: black;
    border: 2px solid rgb(0, 167, 50);
    text-align: center;
    font-size: 12px;
}

.loader-active-slot-div {
    animation: shadow-pulse2 1s infinite;   
    border: 2px solid #007cc794;
    border-radius: 0.5em;
}

.tray-div-no-preview {
    text-align: center;
    font-size: 12px;
}

.rack-header {
    text-align: center;
    width: 100%;
}

.region-selector-div {
    height: 20em;
    border-color: rgba(206, 206, 206, 0.712);
    border-style: solid;
    border-width: 1px;
    border-radius: 0.5em;
    margin: 0.5em;
}

.loader-help-message {
    text-align: center;
    margin-top: 5em;
}

.loader-region-selector-parent {
    border: 1px solid rgba(206, 206, 206, 0.712);
    border-radius: 0.5em;
    margin: 2vw !important;
}

.loader-tray-action-div {
    position: absolute;
    right: -6px;
    top: -6px;
    background: white;
    padding: 0.2em;
    padding-left: 0.6em;
}

.loader-tray-action-right-div {
    position: absolute;
    left: -6px;
    top: -6px;
    background: white;
    padding: 0.2em;
    padding-right: 0.6em;
}

.slot-not-scheduled {
    font-size: 10px !important;
    cursor: default !important;
}

.loader-tray-action {
    cursor: pointer;
}

.loader-tray-action:hover {
    color: #0099ff;
}

.tray-take-preview-button {
    font-size: 12px !important;
}

.loader-active-parent {
    height: 50em !important;
    margin-right: 0.5em !important;
}

.loader-active-slot-child {
    width: 100%;
    height: 46em !important;
}

.tray-slide-preview-img {
    width: 100%;
}

.tray-slide-preview-row, .tray-slide-preview-tag {
    cursor: pointer;
}

.tray-slide-preview-row {
    margin-top: 0 !important;
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
    min-height: 5em !important;
    box-shadow: -2px 0px 0 #000, 2px 0px 0 #000;
}

.tray-slide-preview-row-finished {
    margin-top: 0 !important;
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
    min-height: 2em !important;
    box-shadow: -2px 0px 0 #000, 2px 0px 0 #000;
}

.region-selector-canvas {
    width: 400px;   
    margin: 1em;
}

.active-slot-header {
    margin: 0.5em;
    text-align: center;
    font-size: 14px;
}

.tray-slide-preview-tag {
    padding-left: 0.5em;
    padding-right: 0.5em;
    border-color: rgba(206, 206, 206, 0.712);
    border-style: solid;
    border-width: 1px;
    border-top: 0px;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    margin-left: 0.5em !important;
    margin-right: 0.5em !important;
    margin-bottom: 0.25em !important;
    text-align: center;
    font-size: 10px;
}

.errored {
    background-color: salmon;
}

.tray-header {
    text-align: center;
    font-size: 12px;
}

.tray-divider {
    padding-left: 1em;
    padding-right: 1em;
}