.settings-app-parent {
    width: 15em;
    padding: 0.5em;
    padding-top: 2em;
}

.settings-div {
    font-size: 11px;
}

.settings-button {
    text-align: center;
    height: 2.8em !important;
    border: none !important;
    background-color: rgba(0, 0, 0, 0) !important;
}

.settings-button-col {
    text-align: center;
}

.settings-button-text {
    font-size:10px;
}

.settings-updater {
    margin-top: 1em;
    margin-bottom: 1em;
}

.settings-options {
    margin-left: 1.5em;
}