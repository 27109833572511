.grid-overlay-app-parent {    
    width: 12em;
    height: 18em;
    padding-left: 1em;
    padding-right:1em;
}

.grid-overlay-option-text {
    font-size: 12px;
    color: var(--theme-text-color);
}

.grid-overlay-color-picker {
    width: 100% !important;
    margin-left: auto !important;
    margin-right : auto !important;
    margin-top: 0.5em !important;
    margin-bottom: 0.5em !important;
}