.custom-context{
    border: solid 1px #ccc;
    display: inline-block;
    margin: 5px;
    background: #FFF;
    color: #000;
    font-family: sans-serif;
    cursor: pointer;
    font-size: 12px;
}

.custom-context-item{
    padding: 5px 25px;
    background-color:#696969;
    color:white;
    cursor: pointer;
}

.dotted-bottom-border {
  border-bottom: dotted 1px #ccc;
}

.custom-modal .ant-modal-body {
  padding: 0px;
  font-size: 14px;
  line-height: 1.5;
  width: 300px;
}

.custom-modal .ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: auto;
  width: 300px;
}

.custom-modal .ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0s;
  transition: color 0s;
}

.custom-modal .ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  -webkit-transform: none;
      -ms-transform: none;
          transform: none;
  opacity: 0;
  -webkit-animation-duration: 0s;
          animation-duration: 0s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}