.rounded-container {
    background: #ffffff;
    border-radius:  0.5em;
    margin:0.5em;
}

.scanner-name {
    font-size: 2.5em;
    vertical-align: middle;
    text-align: center
}

.scanner-status {
    font-size:0.5em;
    vertical-align: middle;
}

.scanner-status-error {
    font-size:1.5em;
    text-align: center
}

.click-to-go-to-dash {
    width: 100% !important;
    text-align: center;
}


.scanner-main-control-buttons {
    font-size:1.2em;
    text-align: center;
    padding:1vw;
}

.scanner-start-button { 
    width: 50%;
    margin: 0.5em;
    font-size: 1em !important;
}

.scanner-start-button-div {
    text-align: center;
}

.hardware-status-panel {
    border: 1px #d4d4d4 solid;
    border-radius: 0.5em;
    padding: 0.2em;
    margin-top: 2em;
    margin-bottom: 2em;
}

.hardware-status-row {
    border: 1px #c7bfbf solid;
    border-radius: 0.5em;
    padding: 0.5em !important;
    margin: 0.5em !important;
}

.hardware-section-header {
    text-align: center;
    font-size: 1em;
}

.hardware-section-retry {
    font-size: 0.8em;
}

.power-fail-message {
    text-align: center;
    font-weight: bold;
    font-size: 1em;
}

.troubleshoot-fail-message {
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
}

.troubleshoot-steps {
    margin: 2em;
}

.hardware-status-icon {
    text-align: center;
    font-size: 2em;
    line-height: 3em !important;
    vertical-align: middle;
}

.hardware-status-icon-div{
    text-align: center;
}

.scanner-main-control-icon {
    font-size:3em;
    padding: 1vw;
    padding-bottom: 0.5vw;
}

.scanner-main-control-icon-retake {
    font-size:1em;
    padding: 1vw;
    padding-bottom: 0.5vw;
}

.scanner-busy-card {
    vertical-align: middle;
    text-align: center;
    margin:10vw;
}

.scan-workflow-init {
    vertical-align: middle;
    text-align: center;
    font-size:1.2em;
}

a.scanner-user-controls{
    color:inherit;
}

.slot-header {
    font-size: 1.5em;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif
}

.arial-font {
    font-family: Arial, Helvetica, sans-serif;
}

.region-selection-canvas {
    width:30vw;
    height:10vw;
    background-size: 100% 100%;
    cursor: crosshair;
    position:absolute; top:0px; left:0px;
    z-index: -1;
}

.region-selection-canvas-new {
    width:70vw;
    height:30vw;
    background-size: 100% 100%;
    cursor: crosshair;
    position:absolute; top:0px; left:0px;
    z-index: -1;
}

.brush-eraser-prev-canvas-new {
    width:70vw;
    height:30vw;
    background-size: 100% 100%;
    position:absolute; top:0px; left:0px;
    z-index: -1;
    /* border: 1px solid black; */
}

.preview-canvas-wrapper {
    position:relative;
    width:30vw;
    height:10vw;
    margin: auto;
}

.preview-canvas-wrapper-new {
    position:relative;
    width:70vw;
    height:30vw;
    margin: auto;
}

.summary-slot-div {
    margin-top: 2em;
}

.slide-name-input {
    width: 80% !important;
}

.summary-preview {
    width:30vw;
    height:10vw;
    display: block;
}

.summary-barcode {
    height:10vw;
    display: block;
}

.barcode {
    height:10vw;
    float: left;
    display: block;
}

.barcode-new {
    height:20vw;
    float: left;
    display: block;
}

.redo-slot-text {
    font-size: 10px;
    text-align: center;
}

.redo-slot-button {
    height: auto !important;
}

.summary-preview-list {
    margin-top:2em;
    height: 80vh;
}

.preview-for-region-selection {
    padding:2vw;
}

.slot-menu {
    padding:2vw;
}

.slot-tray-header {
    text-align: center;
    font-size:1.2em;
}

.scan-progress-section {
    text-align: center;
    font-size:1.2em;
}

.scan-progress-section-header {
    color:#777777;
}

.start-scan-button-div, .scan-all-button-div, .view-summary-button-div {
    text-align: center;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 2em;
    padding-top: 0.5em;
}

.scan-all-button-div {
    padding-top: 2em;
}

.view-summary-button-div {
    padding-bottom: 0em;
}

.live-view {
    width: 30vw;
    padding:2em;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.start-scan-button, .scan-all-button {
    animation: shadow-pulse 1s infinite;
}

.device-name {
    color:#5e5e5e;
}

.device-name:link {
    text-decoration: none;
}

.device-name:visited {
    text-decoration: none;
}

.device-name:hover {
    color:#5e5e5e;
    text-decoration: none;
}

@keyframes shadow-pulse
{
     0% {
          box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
     }
     100% {
          box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
     }
}

@keyframes shadow-pulse2
{
     0% {
          box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.7);
     }
     100% {
          box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
     }
}

@keyframes shadow-pulse3
{
     0% {
          box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.3);
     }
     100% {
          box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
     }
}

.relativeParent {
    position: relative;
}

.absoluteChild {
    position: absolute;
    top: 46%;
    left: 47%;
}

.absoluteChildUp {
    position: absolute;
    top: 0%;
    left: 46%;
}

.absoluteChildLeft {
    position: absolute;
    top: 46%;
    left: 0%;
}

.absoluteChildDown {
    position: absolute;
    bottom: 0%;
    left: 46%;
}

.absoluteChildRight {
    position: absolute;
    top: 46%;
    right: 0%;
}

.absoluteChildTextUp {
    position: absolute;
    top: 8%;
    left: 46%;
}

.absoluteChildTextLeft {
    position: absolute;
    top: 48%;
    left: 8%;
}

.absoluteChildTextDown {
    position: absolute;
    bottom: 8%;
    left: 46%;
}

.absoluteChildTextRight {
    position: absolute;
    top: 48%;
    right: 8%;
}

.scanner-name-div {
    height: 5vh;
}
