.slide-meta-app-parent {    
    width: 35em;
    padding-left: 1em;
    padding-right:1em;
}

.app-heading {
    text-align: center;
    margin: 5px;
}

.center-align {
    text-align: center;
}

.top-row-margin {
    margin-top: 10px;
}