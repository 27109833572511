.draggable {
    position: absolute;
    z-index: 9;
    pointer-events: all;
}

.draggable-handle {
    cursor: grab;
    z-index: 10;
    touch-action: manipulation;
    margin-bottom: 0.4em;
    display: inline-block;  
    position: absolute;
    left: 0.2em;
    width: 90%;
    height: 2em;
}

.drag-icon {
    line-height:2em !important;
    margin:auto;
    display:block;
}