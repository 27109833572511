.centered {
    display: flex;
    justify-content: center;
}

.editing-pane {
    margin: 10px;
}

.close-editing-pane {
    font-size: 25px;
}

.active-slot {

}

.preview-pane {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.default-settings {
    height: 570px;
    width: 100%;
    border: 2px solid black;
    margin-top: 30px;
    margin-bottom: 10px;
}

.select-button {
    width: 135px;
}

.blur {
    width:100%;
    height:100%;
    background-size:cover;
    pointer-events: none;
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -ms-filter: blur(4px);
    -o-filter: blur(4px);
    filter: blur(4px);
}

.relative-position {
    position: relative;
}

.load-slides-message-div {
    position: absolute;
    left: 20%;
    top: 10%;
    font-size: 1.3em;
    padding: 0.2em;
    text-align:center;
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.3) !important;
    width: 60%;
    height: 80%;
    display : flex;
    align-items : center;
    background: #fdfefe;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
}

.actionable-buttons-parent-div-set-params {
    display: flex;
    justify-content: center; 
    align-items: right; 
    padding: 20px;
}

.actionable-buttons-parent-div-set-params-index {
    display: none;
    justify-content: center; 
    align-items: right; 
    padding: 20px;
}

.actionable-buttons-div-set-params {
    display: flex;
    justify-content: center;
    align-items: right;
}

.actionable-buttons-set-params {
    width: 230px;
}

.promt-done-button {
    cursor: pointer;
    border: 1px solid black;
    border-radius: 5px; 
    padding: 10px;
}