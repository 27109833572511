.camera-controls-app-width {
    width: 500px;
}

.camera-controls-app-input-field {
    width: 150px;
}

.custom-checkbox-style .ant-checkbox {
    padding: 0px !important;
}