.anno-map-container {
    width: 100% !important;
    height: 70em;
    z-index: 0;
}

.anno-map-row{
    background-color: var(--main-bg-color) !important;
    overflow: hidden;
}

.anno-map-col{
    color: var(--main-bg-color) !important;
    background-color: var(--main-bg-color);
}

.anno-map-operation-chooser {
    position: absolute;
    top: 1em;
    left: 1em;
    z-index: 1;
}

.anno-map-counts-table {
    position: absolute;
    top: 1em;
    right: 1em;
    z-index: 1;
}

.anno-map-counts-save {
    width: 100%;
    margin: 1em;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}