.fixed-component {    
    position: absolute;
    top: 0em;
    right: 0em;
    /* height: 5em !important; */
    width: 19em !important;
    color: black;
    padding-top: 4px !important;
    z-index: 200;
}

.color-row {
    width: 5em;
    height: 1px;
}

.color-scheme-div {  
    position: absolute;
    top: 7em;
    right: 0em;
    z-index: 200;
}