.annotations-app-parent {
    width: var(--annotation-app-width, 35em);
    max-height: var(--annotation-app-height, 40em);
}

.continuous-mode-switch, .continuous-mode-text {
    float: right;
    font-size: 10px;
    margin: 0.2em;
}
    
.annotation-list, .annotations-grid, .selected-annotation-drawer-row {
    min-height: 0em;
    height: auto;
    overflow-y: auto;
    max-height: 25em;
    background-color: rgba(255, 255, 255, 0);
    padding:0.5em;
    margin-bottom: 0.5em;
}

.annotations-grid {
    width: 100%;
}

.grid-switch {
    margin-left: 0.5em;
    margin-bottom: 0.5em;
}

.annotation-crop {
    margin: 0.5em;
    border: 1px solid black;
    border-radius: 0.5em;
    max-width: 90%;
}

.annotation-crop-div {
    display: inline;
}

.annotation-magnification-indicator {
    position: absolute;
    left: 2em;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    vertical-align: middle;
    line-height: 25px;
    opacity: 0.9;
    border: 1px solid;
    border-radius: 10%;
    z-index: 2;
}

.annotation-magnification-indicator-list-view {
    left: 1em;
}

.annotation-magnification-indicator-txtyviewer {
    position: absolute;
    left: 0.5em;
    width: 10px;
    height: 10px;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    vertical-align: middle;
    line-height: 25px;
    opacity: 0.9;
    border-radius: 50%;
    z-index: 2;
}

.delete-annotation-grid-view {
    color: var(--theme-text-color);
    font-size: 15px;
    position: absolute;
    right: 0em;
    background-color: white !important;
    border-radius: 50%;
}

.grid-switch-radio {
    background-color: rgba(0, 0, 0, 0) !important;
}

.grid-switch-radio-present {
    background-color: rgba(0, 0, 0, 0) !important;
    cursor: not-allowed;
}

.annotation-list-item {
    font-size: 12px;
    color: var(--theme-text-color);
}

.delete-annotation {
    color: var(--theme-text-color);
    font-size: 15px;
    position: absolute;
    right: 0.3em;
    z-index: 2;
}

.selected-annotation {
    -webkit-box-shadow: inset 0px 0px 3px 0px rgb(0, 138, 255);
    -moz-box-shadow: inset 0px 0px 3px 0px rgb(0, 138, 255);
    box-shadow: inset 0px 0px 3px 0px rgb(0, 138, 255);
}

.selected-annotation-drawer-row {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: -webkit-fill-available;
}

.selected-annotation-drawer-col {
    padding: 0.5em;
}

.selected-annotation-drawer {
    padding:0.5em;
    font-size: 12px;
    height:auto;
}

.no-annotation-placeholder {
    font-size: 12px;
    text-align: center;
    vertical-align: middle;
    line-height: 4em;
}

.annotation-title {
    text-align: center
}

.ant-typography-edit-content {
    margin: 0.5em;
    padding: 0.5em;
    width: 100%;
}

.annotation-item-shape-icon {
    padding:0.2em;
    left: -0.5em;
    position: absolute;
    font-size:10px;
    background-color: white
}

.color-icon-option {
    border: 1px solid black;
    border-radius: 0.2em; 
    height: 15px;
    width: 80px;
    margin-top: 4px;
}

.annotations-color-polygon {
    border: 1px solid;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    position: absolute;
    z-index: 2;
    margin-right: 3px;
    right: 1em;
}