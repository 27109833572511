.heading-style {
    position: relative;
    padding-left: 15px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 10px;
    box-sizing: border-box;
    display: block;
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    background-color:#f5f5f5;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-font-smoothing: antialiased !important;
}

.sub-heading-style {
    padding: 12px 16px;
}

.main-heading-style {
  padding: 16px 16px;
  font-size: 18px;
}

.case-scans-heading-style {
  font-size: '15px';
}

.add-case-row {
  overflow: hidden;
  padding: 15px;
  font-size: 13px;
  border-bottom: 1px solid #eee;
}

.add-case-row-header {
  padding-top: 6px;
  font-size: 15px;
}

.input-field-add-case {
  width: 350px;
  color: "#000";
  cursor: "auto";
}

.other-field-add-case {
  width: 350px;
}

.submit-row-add-case {
  padding: 12px 14px;
  margin: 0 0 20px;
  background: #f8f8f8;
  border: 1px solid #eee;
  border-radius: 4px;
  text-align: left;
  overflow: hidden;
}

.errors-input {
  border: 1px solid #ba2121;
}

.errormessage {
  margin: 0 0 4px;
  padding: 0;
  color: #ba2121;
  background: #fff;
}

.error-message-general {
  margin-top: 5px;
  padding: 0;
  color: #ba2121;
}



.add-case-button-position, .add-case-button-position:hover {
  padding-right: 26px;
  float: left;
}