@import '../node_modules/antd/es/grid/style/index.css';
@import '../src/asset/style/fonts/ubuntu.css';

$base-font: 'Ubuntu', serif;

:root {
  --main-bg-color: rgba(255, 255, 255, 0.9);   
  --overlayed-button-color: var(--main-bg-color);
  --theme-text-color: rgba(0, 0, 0, 0.8);
}

body{
  background-color: var(--main-bg-color) !important;
  font-family: $base-font !important;
}

.App {
  height: 100%;
}

#root {
  height:100%;
}