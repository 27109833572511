.drawing-tool-icon {
    background-color: rgba(0, 0, 0, 0);
    font-size: 0.8em;
    border-radius: 0.5em;
    padding: 0.2em;
    margin:0.2em;
    text-align: center;
    cursor: pointer;
}

.drawing-tool-icon-text {
    font-size: 0.9em;
}

.drawing-tool-open {
    -webkit-box-shadow: inset 0px 0px 4px 0px rgba(0,0,0,0.46);
    -moz-box-shadow: inset 0px 0px 4px 0px rgba(0,0,0,0.46);
    box-shadow: inset 0px 0px 4px 0px rgba(0,0,0,0.46);
}

.drawing-tool-icon-present {
    background-color: rgba(0, 0, 0, 0);
    font-size: 0.8em;
    border-radius: 0.5em;
    padding: 0.2em;
    margin:0.2em;
    text-align: center;
    cursor: not-allowed;
}